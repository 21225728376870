<template>
  <div class="v-chips">
    <div class="v-chips__label"
      v-if="label">
      {{ label }}
    </div>
    <div class="v-chips__items">
      <div class="v-chips__item-wrapper"
        v-for='(item, i) in items'
        :key='item.label'>
        <input class="v-chips__input"
          v-model="model"
          :type="multiple ? 'checkbox' : 'radio'"
          :id="`${$uuid}-${i}`"
          :name="$uuid"
          :value="item.value ?? item.label"
          :disabled="disabled || item.disabled || false">
        <label class="v-chips__item"
          :for="`${$uuid}-${i}`"
          v-show="item.visible ?? true"
          :style="{ background: color + '05' }"
          @click='select(i)'>
          <div
            v-if="item.src || item.icon">
            <img class="v-chips__item-image"
              v-if="item.src"
              :src="item.src"
              alt>
            <i class="v-chips__item-icon"
              v-if="item.icon"
              :class="`material-symbols${item.isFilled ? '' : '-outlined'}`"
              :style="styleIcons">
              {{ item.icon }}
            </i>
          </div>
          <div class="v-chips__item-label"
            v-html="item.label">
          </div>
        </label>
      </div>
    </div>
    <div class="v-chips__note"
      v-if="items[selected]?.note">
      {{ items[selected].note }}
    </div>
  </div>
  </template>
  
  <script>
  export default {
    emits: [
      "update:modelValue",
      'select'
    ],
    props: {
      modelValue: { type: [Number, String], required: false, default: undefined },
      disabled: { type: Boolean, required: false, default: false },
      label: { type: String, required: false, default: null },
      color: { type: String, required: false, default: '#433298' },
      columns: { type: [Number, String], required: false, default: 2 },
      items: { type: Array, required: true, default: [] },
      multiple: { type: Boolean, required: false, default: false },
      styleIcons: { type: String, required: false, default: undefined },
    },
    created() {
      this.selected = this.items.findIndex(e => e.value==this.modelValue)
    },
    data() { return {
      selected: null,
    }},
    computed: {
      model: {
        get() { return this.modelValue },
        set(val) { this.$emit("update:modelValue", val) }
      }
    },
    methods: {
      select(i) {
        this.selected = i
        const item = this.items[i]
        if (item.action) item.action()
        this.$emit("select", item.value)
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $columns: v-bind(columns);
  $gap: 1em;
  .v-chips {
    &__label {
      margin-bottom: .75em;
      color: #0008;
    }
    &__items {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: $gap;
    }
    &__input {
      display: none;
      &:disabled + label { opacity: .25; }
      &:checked + label {
        font-weight: 500;
        border: 2px solid v-bind(color);
        color: v-bind(color);
        i { color: v-bind(color); }
      }
    }
    &__item-wrapper {
      flex-basis: calc(100% / $columns - $gap / $columns * ($columns - 1));
    }
    &__item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: .5em;
      padding: 1em 1em;
      border: 2px solid #00000015;
      border-radius: .625em;
      text-align: center;
      transition: transform .2s;
      &:active { transform: scale(.9) }
      &:disabled { opacity: .5; }
    }
    &__item-image {
      width: 3.5em;
      display: block;
      object-fit: contain;
      border-radius: 50%;
    }
    &__item-icon {
      display: block;
      font-size: 2.25em;
      color: inherit;
    }
    &__note {
      margin-top: 1em;
      color: #0008;
      font-size: .85em;
      line-height: 1.5;
    }
  }
  </style>