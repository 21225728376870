<template>
<div class="v-pagination">

  <div class="v-pagination__item"
    v-for="(page,i) in pageRange">
    <div class="v-pagination__buttons"
      :style="{ '--col': items[page-1]?.color ?? '#c7c7c7' }">
      <i class="material-symbols-outlined v-pagination__button center-left"
        v-if="isEditable && page==modelValue && page!=1"
        @click="$emit('rearrange', page-1, page-2)">
        arrow_back
      </i>
      <i class="material-symbols-outlined v-pagination__button center-right"
        v-if="isEditable && page==modelValue && page!=pageMax"
        @click="$emit('rearrange', page-1, page)">
        arrow_forward
      </i>
      <i class="material-symbols-outlined v-pagination__button"
        v-if="isEditable"
        :class="{ 'center-right': i==pageRange.length-1, right: i<pageRange.length-1 }"
        @click="$emit('insert', page)">
        add
      </i>
      <i class="left material-symbols-outlined v-pagination__button"
        v-if="i==0 && page!=1"
        @click="pageGoto(1)">
        keyboard_double_arrow_left
      </i>
      <i class="right material-symbols-outlined v-pagination__button"
        v-if="i==pageRange.length-1 && page!=pageMax"
        @click="pageGoto(pageMax)">
        keyboard_double_arrow_right
      </i>
    </div>
    <v-pagination-item
      :isActive="page==modelValue"
      v-bind="{ page, pageMax, ...items[page-1] }"
      @click="pageGoto(page)">
    </v-pagination-item>
  </div>

</div>
</template>

<script>
import VPaginationItem from './v-pagination-item.vue'
export default {
  components: {
    VPaginationItem
  },
  props: {
    isEditable: { type: Boolean, required: false, default: false },
    modelValue: { type: Number, required: false, default: undefined },
    pageMax: { type: Number, required: true, default: 1 },
    pageLimit: { type: Number, required: true, default: 1 },
    pageIds: { type: Array, required: false, default: [] },
    items: { type: Array, required: false, default: [] },
  },
  computed: {
    pageRange() {
      let { modelValue, pageLimit, pageMax } = this
      let pages = Array.from({ length: pageMax }, (_, i) => i+1)
      if (pageMax <= pageLimit) return pages 
      else {
        let n = (pageLimit + 1) / 2
        if (modelValue < n) return pages.slice(0, pageLimit)
        else if (modelValue > pageMax - n - 1) return pages.slice(pageMax - pageLimit, pageMax)
        else return pages.filter(i => Math.abs(i - modelValue) < n)
      }
    },
  },
  methods: {
    pageGoto(page) {
      this.$emit("update:modelValue", page)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-pagination {
  display: flex;
  align-items: center;
  &__item {
    position: relative;
    width: 100%;
  }
  &__buttons {
    display: contents;
  }
  &__button {
    cursor: pointer;
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid var(--col);
    color: var(--col);
    font-size: 1.25em;
    text-align: center;
    &:hover {
      background-color: var(--col);
      color: #fff;
      transition: .5s;
    }
    &.center-left { left: calc(50% - 2.25em) }
    &.center-right { right: calc(50% - 2.25em) }
    &.left { left: 0; }
    &.right {
      right:0;
      transform:translate(50%,-50%);
    }
  }
}
</style>