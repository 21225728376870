<template>
<div class="v-input-image">

  <div class="v-input-image__overlay"
    v-if="isCropperVisible">
    <div class="v-input-image__container">
      <div class="v-input-image__buttons">
        <i class="material-symbols button-icon v-input-image__icon" @click="deleteCropper">arrow_back</i>
        <i class="material-symbols button-icon v-input-image__icon" @click="cropImage">done</i>
      </div>
      <vue-cropper class="v-input-image__cropper"
        ref="cropper"
        :src="imageSrc"
        :aspectRatio="+aspectRatio"
        :autoCropArea="1">
      </vue-cropper>
    </div>
  </div>

  <input class="v-input-image__input"
    type="file"
    ref="input"
    :id="`input-${$uuid}`"
    accept="image/*"
    @change="createCropper($event)"
    @cancel="$emit('cancel')">

  <div v-if="!isHidden">

    <div class="v-input-image__label"
      v-if="label">
      {{ label }}
    </div>

    <div class="v-input-image__preview"
      v-if="hasPreview && imageCroppedSrc">
      <div class="v-input-image__preview-name">
        {{ imageName }}
      </div>
      <img class="v-input-image__preview-image"
        :src="imageCroppedSrc">
    </div>

    <div class="button-pale v-input-image__button"
      v-if="modelValue"
      style="color:red"
      @click="deleteImage()">
      <i class="material-symbols-outlined">hide_image</i>
      {{ labelDelete ?? "Видалити" }}
    </div>

    <div class="v-input-image__content"
      v-if="!isUploading && !modelValue">
      <label class="v-input-image__input-label"
        :for="`input-${$uuid}`"
        v-if="!imageCroppedSrc">
        <slot>
          <div class="button-pale v-input-image__button">
            <i class="material-symbols-outlined">image</i>
            {{ labelUpload ?? "Завантажити" }}
          </div>
        </slot>
      </label>
    </div>

    <div class="v-input-image__spinner"
      v-if="isUploading"
      v-loading="isUploading">
    </div>

  </div>

</div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  components: {
    VueCropper
  },
  emits: [
    'update:modelValue',
    'update:v',
    'preview',
    'uploading',
    'uploaded',
    'submit',
    'cancel',
  ],
  props: {
    isHidden: { type: Boolean, required: false, default: false },
    hasPreview: { type: Boolean, required: false, default: false },
    immediate: { type: Boolean, required: false, default: false },
    modelValue: { type: [Boolean, Number], required: false, default: false },
    v: { type: Number, required: false, default: 0 },
    label: { type: String, required: false, default: undefined },
    labelUpload: { type: String, required: false, default: undefined },
    labelDelete: { type: String, required: false, default: undefined },
    width: { type: [Number, String], required: false, default: undefined },
    aspectRatio: { type: [Number, String], required: false, default: undefined },
    fileName: { type: String, required: false, default: undefined },
    uploadURL: { type: String, required: false, default: undefined },
    formFields: { type: Object, required: false, default: undefined },
  },
  data() { return {
    imageSrc: null,
    imageCroppedSrc: null,
    imageFile: null,
    imageName: undefined,
    form: new FormData(),
    reader: new FileReader(),
    isCropperVisible: false,
    isUploading: false,
    version: 0,
  }},
  created() {
    this.reader.onload = event => {
      this.isCropperVisible = true
      this.imageSrc = event.target.result
    }
  },
  methods: {
    click() {
      this.$refs.input.click()
    },
    generateV() {
      return Math.floor(Math.random() * 9e8) + 1e8
    },
    createCropper(event) {
      const file = event.target.files[0]
      this.imageName = this.fileName ?? file.name
      this.reader.readAsDataURL(file)
      this.$emit('preview')
    },
    async cropImage() {
      this.isCropperVisible = false
      this.imageSrc = null
      const canvas = this.$refs.cropper.getCroppedCanvas({
        width: this.width
      })
      this.imageCroppedSrc = canvas.toDataURL('image/webp')
      canvas.toBlob(async blob => {
        this.imageFile = new File([ blob ], this.fileName)
        this.$emit('submit', this.imageFile, this.imageName)
        this.version = this.generateV()
        if (this.immediate) await this.upload()
        this.$emit('update:modelValue', true)
        this.$emit('update:v', this.version)
      }, 'image/webp')
      this.$refs.input.value = ''
    },
    unsetCropper() {
      this.imageSrc = null
      this.imageFile = null
      this.form = new FormData()
      this.isUploading = false
      this.$refs.input.value = ''
    },
    deleteCropper() {
      this.isCropperVisible = false
      this.unsetCropper()
      this.$emit('cancel')
    },
    deleteImage() {
      this.imageSrc = null
      this.imageCroppedSrc = null
      this.imageFile = null
      this.$emit('update:modelValue', false)
      this.$emit('update:v', 0)
    },
    async upload() {
      this.$emit('uploading')
      this.isUploading = true
      this.form.append('version', this.version)
      if (typeof this.formFields == 'object') {
        Object.entries(this.formFields).forEach(([k,v]) => {
          this.form.append(k, v)
        })
      }
      if (this.imageFile) {
        this.form.append('file', this.imageFile, this.fileName)
      }
      const { data } = await this.$api.post(this.uploadURL, this.form, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      this.$emit('uploaded', data)
      this.unsetCropper()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input-image {
  &__label {
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__input {
    display: none
  }
  &__preview {
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__preview-image {
    height: 3em;
    object-fit: contain;
  }
  &__input-label {
    cursor: pointer;
    width: 100%;
    &:hover { opacity: .85; }
    &:active { opacity: .5; }
    &:disabled {
      cursor: unset;
      opacity: .25;
    }
  }
  &__spinner {
    width: 4em;
    height: 4em;
  }
  &__overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0,0,0,.5);
  }
  &__container {
    z-index: 9;
    width: min(26em, 95%);
    border-radius: 1em;
    box-shadow: 0 2px 6px #0004;
    background: #fff;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
  }
  &__button {
    color: $cmain;
  }
}
</style>