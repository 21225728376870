<template>
<button class="v-button"
  @click="click()">
  <i :class="`material-symbols${isFilled ? '' : '-outlined'} v-button__icon`"
    v-if="icon">
    {{ icon }}
  </i>
  <span class="v-button__label"
    v-if="label">
    {{ label }}
  </span>
  <slot/>
</button>
</template>

<script>
export default {
  props: {
    isFilled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    color: { type: String, required: false, default: "#fff" },
    background: { type: String, required: false, default: "transparent" },
    event: { type: String, required: false, default: undefined },
    payload: { type: Object, required: false, default: undefined },
  },
  methods: {
    click() {
      if (this.event) {
        this.$posthog.capture(this.event, this.payload)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-button {
  background-color: v-bind(background);
  color: v-bind(color);
  &__icon {
    display: block;
  }
}
</style>