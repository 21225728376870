import store from '../store.js'

export default class Task {
  
  constructor(lesson, task, glossary) {

    if (glossary?.length) {

      try {
        const regexpString = `(?<=[\\s,.:;"'> ]|^)(${glossary.map(e => e.term).filter(e => !!e).join('|')})(?=[\\s,.:;"'< ]|$)`
        if (regexpString?.length) {
          const regexp = new RegExp(regexpString, 'gi')
          this.deepReplace(task, 'text', str => {
            return str.replaceAll(regexp, word => {
              return `<span class='tooltip' tooltip="${glossary.find(e => word.match(new RegExp(`${e.term}`, 'i')))?.definition?.replaceAll('"','&quot;')}">${word}</span>`
            })
          })
        }
      }
      catch(err) {
        store.commit('updateState', { isBrowserCompatible: false })
      }

    }

    Object.assign(this, task)
    this.wasEmpty = task.isEmpty
    this.attempt = task.attempt ?? null
    this.percentage = task.weight>0 ? Math.round(+task.score / +task.weight * 100) : 100
    this.isCompleted = this.isAnswered && task.attempt >=0 
    this.isFinished = (this.isCompleted && this.percentage==100) || (this.attempt >= task.settings?.attemptsMax)
    this.isActivity = ['choice','pairs','multiple','multiple_pairs','short_answer','short_answers','essay','file_upload'].includes(task.type)
    this.isManual = ['essay','file_upload'].includes(task.type)

  }
  
  get isAnswered() {
    return this.answer?.every(e => e?.at(0) != undefined)
  }

  deepReplace(obj, keyName, replacer) {
    if (typeof obj !== "object" || obj === null) return
    Object.entries(obj).forEach(([k,v]) => {
      if (Array.isArray(v)) {
        obj[k].forEach(e => this.deepReplace(e, keyName, replacer))
      } 
      else if (typeof v === "object" && v !== null) {
        this.deepReplace(obj[k], keyName, replacer)
      }
      else if (typeof v === "string" && k === keyName) {
        obj[k] = replacer(v)
      } 
    })
  }

}