<template>
<div class="v-viewer"
  v-loading.medium="isLoading">

  <object class="v-viewer__content"
    v-if="ext == 'pdf'"
    :data="url"
    width="100%"
    height="100%"
    @load="$emit('ready')">
  </object>

  <iframe class="v-viewer__content"
    v-else
    :src="url"
    width="100%"
    height="100%"
    @load="$emit('ready')">
  </iframe>

</div>
</template>

<script>
export default {
  emits: [
    'ready',
  ],
  props: {
    src: { type: String, required: true, default: null },
    ext: { type: String, required: true, default: null },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    aspectRatio() {
      return this.ext=='pptx' ? '1.78' : '0.75'
    },
    url() {
      const url = `${this.$s3}/${this.$subjectId}/${this.src}` ?? ''
      if (this.ext=='pdf') return `${url}?size=pdf#view=FitH&navpanes=0&scrollbar=0`
      if (this.ext=='docx' || this.ext=='pptx') return `https://docs.google.com/gview?url=${url}&embedded=true`
      return url
    }
  },
  methods: {
    onLoad() {
      this.$emit('ready')
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.v-viewer {
  max-width: 100%;
  //max-height: 100vh;
  aspect-ratio: v-bind(aspectRatio);
  border: 1px solid #0002;
  &__content {
    display: block;
    border: none;
    background: #0002;
  }
}
</style>