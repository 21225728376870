<template>
<div class="v-download">

  <v-dropdown
    v-bind="bindingDropdown"
    align="right">
  </v-dropdown>

</div>
</template>

<script>
import writeXlsxFile from 'write-excel-file'
export default {
  props: {
    data: { type: Array, required: true, default: [{}] },
    types: { type: Array, required: false, default: ['xlsx','csv'] },
    fileName: { type: [String, Date], required: false, default: new Date() },
  },
  computed: {
    bindingDropdown() {
      return {
        title: { icon: 'download', style: 'font-size:1.5em' },
        items: [
          { label: "Завантажити як XLSX", icon: "table", action: this.downloadAsXLSX, visible: this.types.includes('xlsx') },
          { label: "Завантажити як CSV", icon: "csv", action: this.downloadAsCSV, visible: this.types.includes('csv') },
          { label: "Завантажити як JSON", icon: "json", action: this.downloadAsJSON, visible: this.types.includes('json') },
        ]
      }
    },
  },
  methods: {
    expandObject(obj) {
      const objExpanded = {}
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            objExpanded[`${key}.${index}`] = item
          })
        }
        else objExpanded[key] = obj[key]
      }
      return objExpanded
    },
    dataToTable(arrOfObjects, cb = e => e) {
      arrOfObjects = arrOfObjects.map(obj => this.expandObject(obj))
      const keysSet = arrOfObjects.reduce((acc, obj) => {
        Object.keys(obj).forEach(key => acc.add(key))
        return acc
      }, new Set())
      const keys = Array.from(keysSet)
      return [
        keys.map(e => cb(e)),
        ...arrOfObjects.map(obj => keys.map(key => cb(obj[key])))
      ]
    },
    downloadBlob(content, options) {
      const blob = new Blob([content], options)
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = this.fileName
      a.click()
      URL.revokeObjectURL(url)
    },
    async downloadAsCSV() {
      const arrOfArrays = this.dataToTable(this.data)
      const content = arrOfArrays.join('\r\n')
      this.downloadBlob(content, { type: `text/csv` })
    },
    downloadAsJSON() {
      const content = JSON.stringify(this.data)
      this.downloadBlob(content, { type: `text/json` })
    },
    async downloadAsXLSX() {
      const data = this.dataToTable(this.data, e => ({ value: e }))
      await writeXlsxFile(data, {
        fileName: this.fileName,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-download {
  font-size: 1rem;
}
</style>