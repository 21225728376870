import { createApp } from 'vue'
import vModal from './v-modal.vue'
import modalInfo from './modal-info.vue'
import modalConfirm from './modal-confirm.vue'
import modalDictionary from './modal-dictionary.vue'

const close = function(parentEl, app) {
  parentEl?.parentNode?.removeChild(parentEl)
  app.unmount()
  app = null
}

const popupInfo = function(message, title, icon=undefined, color=undefined, action) {
  const el = document.createElement('div')
  document.body.appendChild(el)
  const popup = createApp(
    { extends: modalInfo },
    {
      modelValue: true,
      message,
      title,
      icon,
      color,
      action,
      onClose: function() { close(el, popup) }
    }
  )
  popup.mount(el)
}

const popupError = (message, title='Помилка', icon="close", action) => popupInfo(message, title, icon, "#ff4d4d", action)
const popupSuccess = (message, title='Успішно', icon="done") => popupInfo(message, title, icon, "#4da64d")

const popupDictionary = function(payload) {
  const el = document.createElement('div')
  document.body.appendChild(el)
  const { title, message, labels, data } = payload
  const popup = createApp(
    { extends: modalDictionary }, 
    {
      modelValue: true,
      title,
      message,
      labels,
      data,
      onClose: function() { close(el, popup) }
    }
  )
  popup.mount(el)
}

const popupConfirm = function(header, message, labelConfirm='Так', labelDiscard='Скасувати',) {
  const el = document.createElement('div')
  document.body.appendChild(el)
  let popup = createApp(
    { extends: modalConfirm }, 
    {
      modelValue: true,
      header,
      message,
      onClose: function() { close(el, popup) }
    }
  )
  const popupRoot = popup.mount(el)
  popupRoot.promise.then(res => close(el, popup))
  return popupRoot.promise
}

export default {
  popupInfo,
  popupSuccess,
  popupError,
  popupConfirm,
  install: (app, options) => {
    app.component('vModal', vModal)
    app.config.globalProperties.$popupInfo = popupInfo
    app.config.globalProperties.$popupSuccess = popupSuccess
    app.config.globalProperties.$popupError = popupError
    app.config.globalProperties.$popupDictionary = popupDictionary
    app.config.globalProperties.$popupConfirm = popupConfirm
  }
}