<template>
<loader
  v-if="$store.state.loader.isVisible"
  :bgColor="$store.state.loader.bgColor">
</loader>

<div id="app-content"
  v-loading.large="!$user._id">

  <router-view 
    v-if="$route.meta.keepAlive"
    v-slot="{ Component }">
    <keep-alive>
      <component 
        :is="Component" 
        :key="$route.path">
      </component>
    </keep-alive>
  </router-view>

  <router-view 
    v-else>
  </router-view>

</div>
</template>

<script>
import Loader from '@/components/loader.vue'
export default {
  components: { 
    Loader,
  },
  async beforeCreate() {
    this.$store.commit('showLoader', { bgColor: '#0004' })
    window.addEventListener('orientationchange', function () {
      const originalBodyStyle = getComputedStyle(document.body).getPropertyValue('display')
      document.body.style.display='none'
      setTimeout(() => {
        document.body.style.display = originalBodyStyle
      }, 10)
    })
    window.addEventListener('mouseover', event => {
      if (event.target.classList.contains('tooltip')) {
        const tooltip = event.target
        const rect = tooltip.getBoundingClientRect()
        const xc = rect.x + rect.width / 2
        if (xc < this.$screen.width / 2) {
          tooltip.classList.add('left')
          tooltip.classList.remove('right')
        }
        else {
          tooltip.classList.add('right')
          tooltip.classList.remove('left')
        }
      }
    })
  },
  computed: {
    zoom() {
      return this.$store.state.zoom
    },
  },
}
</script>

<style lang="scss">
@import './styles/styles.scss';
@import './styles/variables.scss';
@import './styles/transitions.scss';
#app-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  src: url('@/assets/fonts/Gilroy-Regular.woff2') format("woff2");
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: url('@/assets/fonts/Gilroy-Medium.woff2') format("woff2");
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: url('@/assets/fonts/Gilroy-SemiBold.woff2') format("woff2");
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: url('@/assets/fonts/Gilroy-Bold.woff2') format("woff2");
}

#app-content {
  position: relative;
  //z-index: -1;
  //overflow-x: hidden;
  font-family: 'Gilroy';
  transition: font-size 0.3s ease;
  @media (min-width:960px) { font-size: calc(16px * v-bind(zoom)); }
  @media (min-width:640px) and (max-width:960px) { font-size: calc(15px * v-bind(zoom)); }
  @media (max-width:640px) { font-size: calc(14px * v-bind(zoom)); }
}
</style>
