<script>
import { h, Transition } from 'vue'
import vTabsHeader from './v-tabs-header.vue'
export default {
  props: {
    modelValue: { type: Number, required: false, default: 0 },
    isAnimated: { type: Boolean, required: false, default: false },
    hasLabels: { type: Boolean, required: false, default: true },
    isHeaderVisible: { type: Boolean, required: false, default: true },
    isLabelVisible: { type: Boolean, required: false, default: false },
    items: { type: Array, required: true },
    style: { type: Object, required: false, default: {} },
    colorActive: { type: String, required: false, default: '#ff781f' },
  },
  data() { return {
    vnodes: this.$slots.default().filter(e => e.type.name == 'v-tabs-content'),
    transitionName: null,
  }},
  render() {
    return h('div',
      { style: 'position: relative' }, 
      [
        !this.isHeaderVisible ? h('div') :
        h(vTabsHeader, {
          modelValue: this.modelValue,
          class: 'v-tabs__header',
          items: this.items,
          style: this.style,
          colorActive: this.colorActive,
          hasLabels: this.hasLabels,
          isLabelVisible: this.isLabelVisible,
          'onUpdate:modelValue': value => this.$emit('update:modelValue', value)
        }),
        h(Transition, 
          { name: this.transitionName, mode: "out-in" }, 
          _ => this.vnodes[this.modelValue]
        )
      ]
    )
  },
  watch: {
    'items.length'() {
      this.vnodes = this.$slots.default().filter(e => e.type.name == 'v-tabs-content')
    },
    modelValue(val, oldVal) {
      window.scrollTo(0,0)
      if (val > oldVal) this.transitionName = 'slide-left'
      if (val < oldVal) this.transitionName = 'slide-right'
    }
  }
}
</script>

<style lang='scss'>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: .2s;
}
.slide-left-enter-from {
  right: 0;
  transform: translateX(100%);
}
.slide-left-leave-to {
  opacity: 0;
  left: 0;
  transform: translateX(-100%);
}
.slide-right-enter-from {
  left: 0;
  transform: translateX(-100%);
}
.slide-right-leave-to {
  opacity: 0;
  right: 0;
  transform: translateX(100%);
}

.v-tabs {
  &__header {
    font-weight: 500;
  }
}
</style>