<template>
  <div class="v-tabs-header">
  
    <div class="v-tabs-header__current"
      v-if='isLabelVisible'>
      <i class="material-symbols v-tabs-header__icon">
        {{ items[modelValue]?.icon }}
      </i>&ensp;
      <span>{{ items[modelValue]?.label }}</span>
    </div>

    <slot>
      <div></div>
    </slot>
  
    <div class="v-tabs-header__tabs"
      :style="style">
      <div class="v-tabs-header__titles">
        <div class="v-tabs-header__title"
          v-for="(item, i) in items" 
          :key="item.label || item.icon"
          :class="{ selected: i==modelValue }"
          @click="click(i)">
          <i class="material-symbols v-tabs-header__icon"
            v-if="item.icon">
            {{ item.icon }}
          </i>
          <span class="v-tabs-header__label"
            v-if="hasLabels && item.label">
            {{ item.label }}
          </span>
        </div>
      </div>
      <div class="v-tabs-header__hrs">
        <TransitionGroup name="list">
          <hr class="v-tabs-header__hr"
            :class="{ selected: i==0 }"
            v-for="i in keys"
            :key="`${$uuid}-${i}`">
        </TransitionGroup>
      </div>
    </div>
    
  </div>
  </template>
  
  <script>
  export default {
    emits: [
      'update:modelValue',
    ],
    props: {
      modelValue: { type: Number, required: false, default: 0 },
      items: { type: Array, required: true, default: [] },
      hasLabels: { type: Boolean, required: false, default: true },
      isLabelVisible: { type: Boolean, required: false, default: false },
      style: { type: Object, required: false, default: {} },
    },
    data() { return {
      keys: [],
    }},
    created() {
      this.keys = Array.from({ length: this.itemsDisplayed.length }, (e,i) => i)
      if (this.modelValue) {
        [this.keys[this.modelValue], this.keys[0]] = [this.keys[0], this.keys[this.modelValue]]
      }
    },
    computed: {
      itemsDisplayed() {
        return this.items.filter(e => e.visible ?? true)
      }
    },
    methods: {
      async click(i) {
        if (this.itemsDisplayed[i].action) {
          await this.itemsDisplayed[i].action()
        }
        const j = this.keys.indexOf(0)
        const b = this.keys[j]
        this.keys[j] = this.keys[i]
        this.keys[i] = b
        this.$emit('update:modelValue', i)
      }
    },
    watch: {
      modelValue(i) {
        this.click(i)
      },
      'items.length'() {
        this.keys = Array.from({ length: this.itemsDisplayed.length }, (e,i) => i)
      }
    }
  }
  </script>
  
  <style lang='scss' scoped>
  .v-tabs-header {
    margin-bottom: 1.5em;
    user-select: none;
    &__tabs {
      cursor: pointer;
      display: inline-block;
      width: 100%;
    }
    &__current {
      margin-left: 10px;
      font-size: 1.15em;
      font-weight: bold;
    }
    &__titles, &__hrs {
      display: flex;
      align-items: center;
      color: inherit;
      * {
        flex-basis: 100%;
        text-align: center;
      }
    }
    &__title {
      padding: 10px 5px;
      color: inherit;
      overflow: hidden;
      white-space: nowrap;
      &.selected {
        color: $cmain;
      }
    }
    &__icon {
      font-size: 1.33em;
      font-weight: 400;
      vertical-align: middle;
    }
    &__label {
      margin-left: 5px;
      vertical-align: middle;
    }
    &__hrs {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0; right: 0;
        border-bottom: 1px solid;
        opacity: .5;
      }
    }
    &__hr {
      position: relative;
      margin: 0;
      border: none;
      &.selected {
        background: $cmain;
        height: 3px;
      }
    }
  }
  
  .list-move {
    transition: all .25s ease;
  }
  </style>
  