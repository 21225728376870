import './v-loading-styles.css'

let isLoading = false, position = 'initial'

const toggleLoader = (el, { value, arg, modifiers }) => {
  if (value) {
    if (!!el.querySelector('.v-loading__spinner')) return
    position = el.style.position || 'initial'
    el.style.position = 'relative'
    let spinnerWrapper = document.createElement('div')
    spinnerWrapper.classList.add("v-loading")
    let spinner = document.createElement('div')
    spinner.classList.add("v-loading__spinner", ...Object.keys(modifiers))
    if (arg) spinner.style.borderColor = `transparent ${arg}`
    el.appendChild(spinnerWrapper)
    spinnerWrapper.appendChild(spinner)
    isLoading = true
  }
  else {
    el.style.position = position
    el.querySelector('.v-loading__spinner')?.remove()
    isLoading = false
  }
}

export default {

  mounted: (el, binding) => {
    toggleLoader(el, binding)
  },
  beforeUpdate: (el, binding) => {
    toggleLoader(el, binding)
  },

}